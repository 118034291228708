// extracted by mini-css-extract-plugin
export var card = "memberDetailCard-module--card--BVxJE";
export var followText = "memberDetailCard-module--follow-text--XOV0n";
export var header = "memberDetailCard-module--header--142nZ";
export var image = "memberDetailCard-module--image--APDFp";
export var info = "memberDetailCard-module--info--Q2mxw";
export var name = "memberDetailCard-module--name--XLcP3";
export var share = "memberDetailCard-module--share--OQwHA";
export var speaker = "memberDetailCard-module--speaker--qC-uj";
export var summary = "memberDetailCard-module--summary--22ZoG";
export var summaryLink = "memberDetailCard-module--summary-link--RJmYN";
export var summaryText = "memberDetailCard-module--summary-text--e2MxY";